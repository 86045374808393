import { Fragment, useEffect, useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useTranslation } from "react-i18next";
import { CardBusiness, CardBusinessContainer, Dot, ExpandIcon } from "../../../screens/BusinessSwitch/styles";
import { Colors } from "../../../values/colors";
import { FlatButton, Title, View } from "../../BaseComponents/styles";
import Image from "../../Image";
import { Line } from "../../Line/styles";
import DefaultImage from "../../../assets/images/profile_business.jpg";
import Auth from "../../../services/Auth";
import autoAnimate from "@formkit/auto-animate";
import { Spinner } from "react-activity";
import { FormField } from "../../FormField";

export const BusinessList = ({ onClose, show }) => {
  const [ t, i18n ] = useTranslation('common');
  const [ businesses, setBusinesses ] = useState([]);
  const [ filteredBusinesses, setFilteredBusinesses ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const listRef = useRef(null);

  useEffect(() => {
    if(show == true) getData()
  }, [show])

  useEffect(() => {
    listRef.current && autoAnimate(listRef.current)
  }, [listRef])
  
  const getData = async () => {
    setLoading(true);
    const data = await Auth.getBusinesses();
    setBusinesses(data.list)
    setFilteredBusinesses(data.list);
    setLoading(false);
  }

  const switchBusiness = async (id) => {
    await Auth.switchBusiness(id);
    window.location.href= "/";
  }

  const RenderBusiness = ({business}) => {

    const hasChildren = !!business.children.length;

    const [ showChildren, setShowChildren ] = useState(business.level === 1 ? true : false);
    const dropdownRef = useRef();

    useEffect(()=>{
      if(dropdownRef.current) autoAnimate(dropdownRef.current);
    }, [dropdownRef])

    return (
      <CardBusinessContainer
        key={business.id}
        ref={dropdownRef}
        className={
          `${business.level > 2 ? 'child' : ''} ${business.level > 5 ? 'limit' : ''}`
        }>
        <Line>
          <CardBusiness 
            className={`${hasChildren ? 'parent' : ''}`} 
            onClick={() => 
              switchBusiness(business.id)
              }>
            <Line style={{width: '80%'}}>
              <Image src={DefaultImage} width={36} height={36} style={{borderRadius: '100%', marginRight: '1rem'}}/>
              <span className="text">{business.title}</span>
            </Line>
            {hasChildren ?
              <FlatButton
                className={hasChildren ? 'button' : ''}
                onClick={(e)=>(e.stopPropagation(),setShowChildren(!showChildren))}
                >
                  <ExpandIcon className={showChildren ? 'show' : ''} />
              </FlatButton>
            :
            <ChevronRight color={Colors.primaryColor}/>
            }
          </CardBusiness>
        </Line>
        {showChildren && <div>
          {business.children.map((c) => <RenderBusiness key={c.id} business={c}/>)}
        </div>}
      </CardBusinessContainer>
    )
  }

  const searchTimeout = useRef();
  const onSearch = (value) => {
    if(searchTimeout.current) clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      function titleSearch(data, termoBusca) {
        return data
          .map((empresa) => {
            const corresponde = empresa.title.toLowerCase().includes(termoBusca.toLowerCase());
            
            const filhosFiltrados = empresa.children ? titleSearch(empresa.children, termoBusca) : [];
            
            if(corresponde && filhosFiltrados.length == 0){
              return {
                ...empresa, children: empresa.children
              }
            }

            if (corresponde || filhosFiltrados.length > 0) {
              return { ...empresa, children: filhosFiltrados };
            }

            return null;
          })
          .filter(Boolean);
      }
      setFilteredBusinesses(titleSearch(businesses, value));
    }, 400);
  }

  return (
    <View>
      <Line>
        <ChevronLeft color={Colors.lightGray} onClick={onClose}/>
        <Title style={{marginLeft: '1rem'}}>{t('Selecione uma empresa para visualizar')}</Title>
      </Line>

      <FormField placeholder={'Pesquise uma empresa'} onChangeValue={onSearch}/>

      <View style={{marginTop: '2rem'}} ref={listRef}>
        
        {loading ?
          <center><Spinner color={Colors.primaryPurple} style={{marginTop: 100}} size={24}/></center>
        : filteredBusinesses.map(business => 
          <RenderBusiness key={business.id} business={business}/>
        )}
      </View>
    </View>
  );
}